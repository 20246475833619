import { FCC } from '~/core/@types/global'

const LPContainer: FCC<{ className?: string }> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={`m-auto max-w-[1216px] px-4 tablet:px-6 desktop:px-0 ${className}`}>
      {children}
    </div>
  )
}

export default LPContainer
