import { FCC } from '~/core/@types/global'
import HeaderLandingPage from './components/HeaderLandingPage'
import FooterLandingPage from './components/FooterLandingPage'

interface LayoutLandingPageProps {
  isHidRequestDemoButton?: boolean
  customContainerHeader?: string
}

const LayoutLandingPage: FCC<LayoutLandingPageProps> = (props) => {
  const { children, isHidRequestDemoButton, customContainerHeader } = props

  return (
    <div className="relative">
      <HeaderLandingPage
        sticky
        isHidRequestDemoButton={isHidRequestDemoButton}
        customContainerHeader={customContainerHeader}
      />
      {children}
      <FooterLandingPage />
    </div>
  )
}

export default LayoutLandingPage
