import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { FC, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import configuration from '~/configuration'

/**
 * commom resources for en
 */
import en_common from '../../public/locales/en/common.json'
import en_auth from '../../public/locales/en/auth.json'
import en_form from '../../public/locales/en/form.json'
import en_button from '../../public/locales/en/button.json'
import en_label from '../../public/locales/en/label.json'
import en_notification from '../../public/locales/en/notification.json'

/**
 * commom resources for vi
 */
import vi_common from '../../public/locales/vi/common.json'
import vi_auth from '../../public/locales/vi/auth.json'
import vi_form from '../../public/locales/vi/form.json'
import vi_button from '../../public/locales/vi/button.json'
import vi_label from '../../public/locales/vi/label.json'
import vi_notification from '../../public/locales/vi/notification.json'

/**
 * commom resources for vi
 */
import ja_common from '../../public/locales/ja/common.json'
import ja_auth from '../../public/locales/ja/auth.json'
import ja_form from '../../public/locales/ja/form.json'
import ja_button from '../../public/locales/ja/button.json'
import ja_label from '../../public/locales/ja/label.json'
import ja_notification from '../../public/locales/ja/notification.json'

const defaultEnResource = {
  common: en_common,
  auth: en_auth,
  form: en_form,
  button: en_button,
  label: en_label,
  notification: en_notification
}
const defaultviResource = {
  common: vi_common,
  auth: vi_auth,
  form: vi_form,
  button: vi_button,
  label: vi_label,
  notification: vi_notification
}
const defaultjaResource = {
  common: ja_common,
  auth: ja_auth,
  form: ja_form,
  button: ja_button,
  label: ja_label,
  notification: ja_notification
}

const withTranslateProvider = <F extends object>(
  Component: FC<F>,
  pageResources: {
    en: { [nameSpace: string]: object }
    vi: { [nameSpace: string]: object }
    ja: { [nameSpace: string]: object }
  } = { en: {}, vi: {}, ja: {} }
) => {
  const WithTranslateProvider = <T extends F>(props: T) => {
    const router = useRouter()
    useEffect(() => {}, [])
    const i18nInstance = useMemo(() => {
      i18n.use(initReactI18next).init({
        resources: {
          en: { ...defaultEnResource, ...pageResources.en },
          vi: { ...defaultviResource, ...pageResources.vi },
          ja: { ...defaultjaResource, ...pageResources.ja }
        },
        lng: router.locale,
        fallbackLng: configuration.site.locale,
        interpolation: {
          escapeValue: false
        }
      })
      return i18n
    }, [router.locale])
    return (
      <I18nextProvider i18n={i18nInstance}>
        <Component {...props} />
      </I18nextProvider>
    )
  }
  return WithTranslateProvider
}
export default withTranslateProvider
