import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import Link from 'next/link'
import { ICONS } from '~/core/constants/resources'
import Image from 'next/image'

type SubItem = {
  label: string
  subLabel?: string
  icon?: string
  href: string
  target?: string
}

type NavItem = {
  label: string
  href: string
  subItems?: SubItem[]
  target?: string
}
export type NavItemsProps = NavItem[]

const NavigationBar = ({ content }: { content: NavItemsProps }) => {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null)

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index)
  }

  const closeDropdown = () => {
    setDropdownOpen(null)
  }

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (!target.closest('.dropdown-toggle')) {
        closeDropdown()
      }
    }

    window.addEventListener('scroll', closeDropdown)
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
      window.removeEventListener('scroll', closeDropdown)
    }
  }, [])

  return (
    <nav>
      <div className="hidden tablet:flex ml-16 navigation-menu">
        {content.map((item, index) => {
          const key = item.label
            .replace(/\s+/g, '-')
            .toLowerCase()
            .concat(`-${index}`)
          const hasSub = item.subItems && item.subItems?.length > 0

          return (
            <div className="relative" key={key}>
              {hasSub ? (
                <>
                  <button
                    type="button"
                    className={`dropdown-toggle flex items-center gap-2 cursor-pointer border border-transparent rounded-md px-4 py-1.5`}
                    onClick={() => toggleDropdown(index)}>
                    <span className="pointer-events-none select-none font-medium text-[17px] text-gray-900">
                      {`${t('lp:' + item.label)}`}
                    </span>
                    <Image
                      src={ICONS.chevronDown}
                      width={0}
                      height={0}
                      alt={`chevronDown${index}`}
                      className={`w-3 h-auto transition-all transform ${index === dropdownOpen && '-rotate-180'}`}
                    />
                  </button>
                  <div
                    className={`dropdown-menu absolute ${
                      dropdownOpen === index ? '' : 'hidden'
                    } z-20 mt-2 w-max bg-white border rounded-lg border-gray-100 shadow-md p-2`}>
                    {item.subItems?.map((subItem, subIndex) => (
                      <Link
                        href={subItem.href}
                        target={item.target || '_blank'}
                        key={subIndex}
                        className={`block px-[18px] py-2 hover:bg-gray-100 rounded-lg`}>
                        <div className="flex gap-3 items-center">
                          {subItem.icon && (
                            <img
                              src={`/img/feature/lp/${subItem.icon}.png`}
                              alt="user_permission"
                              width={40}
                              className=""
                            />
                          )}
                          <div className="flex flex-col gap-[2px]">
                            <span className="font-medium text-[17px] leading-6">{`${t('lp:' + subItem.label)}`}</span>
                            {subItem.subLabel && (
                              <span className="text-[15px] leading-6 text-gray-700">{`${t('lp:' + subItem.subLabel)}`}</span>
                            )}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                <Link
                  href={item.href}
                  target={item.target || '_blank'}
                  className="block font-medium text-[17px] text-gray-900 px-4 py-1.5">
                  {`${t('lp:' + item.label)}`}
                </Link>
              )}
              {/* <li className="flex items-center cursor-pointer border border-transparent hover:bg-gray-100 rounded-md px-4 py-1.5 font-medium text-[17px] text-gray-900">
                {`${t('lp:' + item.label)}`}
                {hasSub && (
                  <ChevronDownIcon className="ml-1 inline-block h-4 w-4 text-gray-900" />
                )}
              </li> */}
            </div>
          )
        })}
      </div>
    </nav>
  )
}
export default NavigationBar
